// Generated by Framer (12ecc5e)

import { addFonts, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-4xgml"

const variantClassNames = {kWdOgu4JX: "framer-v-cazj2w"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "kWdOgu4JX", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-cazj2w", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"kWdOgu4JX"} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-1x44fpn"} data-framer-name={"Graphic"} layout={"position"} layoutDependency={layoutDependency} layoutId={"UYMF7FYB_"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 641 1137\"><path d=\"M 7.005 1135.251 L 7.005 503.778 L 145.363 365.589 L 145.363 739.925 L 243.44 739.925 L 243.44 17.492 L 366.036 139.938 L 366.036 330.605 L 402.814 330.605 M 632.243 739.925 L 632.243 330.605 L 565.691 330.605 M 499.139 330.605 L 499.139 503.778 M 499.139 330.605 L 565.691 330.605 M 499.139 330.605 L 402.814 330.605 M 565.691 330.605 L 565.691 412.818 M 402.814 330.605 L 402.814 843.129\" fill=\"transparent\" stroke-width=\"3\" stroke=\"rgb(255,255,255)\" stroke-miterlimit=\"10\" stroke-dasharray=\"\"></path></svg>"} svgContentId={11746583577} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-4xgml.framer-1qfe05n, .framer-4xgml .framer-1qfe05n { display: block; }", ".framer-4xgml.framer-cazj2w { height: 1137px; overflow: hidden; position: relative; width: 641px; }", ".framer-4xgml .framer-1x44fpn { flex: none; height: 1137px; left: 0px; position: absolute; top: 0px; width: 641px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1137
 * @framerIntrinsicWidth 641
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameryrA1wVy_Y: React.ComponentType<Props> = withCSS(Component, css, "framer-4xgml") as typeof Component;
export default FrameryrA1wVy_Y;

FrameryrA1wVy_Y.displayName = "Graphic";

FrameryrA1wVy_Y.defaultProps = {height: 1137, width: 641};

addFonts(FrameryrA1wVy_Y, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})